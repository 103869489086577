import React from 'react'
import { graphql, Link, useStaticQuery } from 'gatsby'
import { Container, Row, Col, Button } from 'react-bootstrap'
import { GatsbyImage } from 'gatsby-plugin-image'
import { StaticImage } from 'gatsby-plugin-image'
import { useIntl } from 'gatsby-plugin-react-intl'

import * as styles from '../../styles/components/Doctors/doctors.module.scss'
import Title from '../title'

export default function Doctors () {
  const data = useStaticQuery(graphql`
    query {
      allContentfulDoctor(sort: { fields: name }) {
        edges {
          node {
            name
            specialization
            slug
            image {
              gatsbyImageData(width: 300, height: 300)
            }
          }
        }
      }
    }
  `)

  const intl = useIntl()

  return (
    <>
      <Title title={intl.formatMessage({ id: 'OurProviders' })} />
      <Container>
        <Row xs={2} sm={3} md={4} className='g-4 my-5 py-5'>
          {data.allContentfulDoctor.edges.map(edge => {
            const { name, image, specialization, slug } = edge.node
            return (
              <Col className={styles.doctor}>
                <div className='p-5'>
                  {edge.node.image ? (
                    <GatsbyImage image={image.gatsbyImageData} alt='Doctor' />
                  ) : (
                    <StaticImage
                      src='../../images/placeholder.png'
                      alt='placeholder'
                      height={300}
                      width={300}
                    />
                  )}
                  <h2 className={`mt-5 mb-4 ${styles.title}`}>{name}</h2>
                  {specialization && <p>{specialization}</p>}

                  <Link to={`/providers/${slug}`}>
                    <Button
                      className={`${styles.btn} mt-5 p-3 p-md-auto`}
                      variant='tertiary'
                    >
                      {intl.formatMessage({ id: 'ReadBio' })}
                    </Button>
                  </Link>
                </div>
              </Col>
            )
          })}
        </Row>
      </Container>
    </>
  )
}
