import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Doctors from "../components/Doctors/doctors"

const Providers = () => (
  <Layout>
    <Seo title="Providers" description="Learn more about our providers at Kids &amp; Teens Medical Group.  Our team of experts provides a variety of pediatric services to the communities of Los Angeles and surrounding neighborhoods. To book an appointment, call our offices at 818-244-9595" />
    <Doctors />

  </Layout>
)

export default Providers
